




































































































import { Component, Ref, Prop, Vue, Watch } from 'vue-property-decorator'
import { FormManifesto, Manifesto, Usuario, Loja } from '@/models'
import SeletorDeLojas from '@/components/loja/SeletorDeLojas.vue'
import SeletorDeMotorista from '@/components/usuario/SeletorDeMotorista.vue'
import DataTableDeCrudPaginado from '@/components/ui/DataTableDeCrudPaginado.vue'
import DialogoDeDetalhesDaVenda from '@/components/venda/DialogoDeDetalhesDaVenda.vue'
import { obrigatorio } from '@/shareds/regras-de-form'
import EdicaoGenerica from '@/components/layout/EdicaoGenerica.vue'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'
import { InserirItemNoManifestoUseCase } from '@/usecases/manifesto/InserirItemNoManifestoUseCase'
import { CreateManifestoUseCase } from '@/usecases'

@Component({
	components: {
		SeletorDeLojas,
		SeletorDeMotorista,
		DataTableDeCrudPaginado,
		DialogoDeDetalhesDaVenda,
		EdicaoGenerica,
	},
})
export default class EdicaoDeManifestoDeTransporte extends Vue {
	@Prop({ type: Boolean }) novo!: boolean
	@Prop({ type: String }) id!: string	
	@Ref() formDeItem!: HTMLFormElement
	@Ref() campoDeNota!: HTMLInputElement
	@Ref() campoDaLoja!: HTMLInputElement	

	inserirItemNoManifesto = new InserirItemNoManifestoUseCase()

	erro: null | Error = null
	buscando = false
	carregando = true
	obrigatorio = obrigatorio
	salvando = false
	usuario: Usuario | null = null
	manifesto: FormManifesto = criarManifesto()
	loja: Loja | null = null	
	itemDoManifesto: NotaESerie = criarItemDoManifesto()
	createManifestoUseCase = new CreateManifestoUseCase()


	headers = [
		{ text: 'Nota', value: 'nnf' },
		{ text: 'Serie', value: 'serie' },
		{ text: 'Ações', value: 'actions' },
	]

	get idUsuarioLogado(): string | undefined {
		return UserLoginStore.usuario?.id || undefined
	}

	get possuiAcessoIrrestrito() {
		return UserLoginStore.permiteRegraDeNegocio('acesso-irrestrito')
	}	

	get itensFormatados() {
		return this.manifesto.itens.map(nota => ({
			...nota,
		}))
	}

	displaySituacao(manifesto: Manifesto) {
		const situacaoDoManifesto = manifesto.situacao
		return situacaoDoManifesto
	}

	@Watch('id', { immediate: true })
	onChangeId() {
		this.carregar()
	}

	async carregar() {	
		try {
			this.carregando = true
			this.manifesto = criarManifesto()
		} catch (error: any) {
			this.erro = error
			AlertModule.setError(error)
		} finally {
			this.$nextTick(() => {
				this.carregando = false
			})
		}
	}

	async salvar() {
		if (!this.manifesto) return
		if (this.manifesto.itens.length == 0) return

		
		try {
			this.salvando = true			
			
			await this.createManifestoUseCase.create(this.manifesto);
			AlertModule.setSuccess('Manifesto salvo com sucesso!')			
			this.carregar()			
		} catch (error: any) {
			AlertModule.setError(error)
		} finally {
			this.salvando = false
		}
	}

	async incluirItemNoManifesto() {
		if (!this.formDeItem.validate()) return
		
		try {
			this.buscando = true;

			this.manifesto.itens.forEach(item => {
				if ((item.nnf == this.itemDoManifesto.numeroDaNota) && (item.serie == this.itemDoManifesto.serieDaNota)) {
					this.itemDoManifesto.numeroDaNota = ''
					this.itemDoManifesto.serieDaNota = ''
					this.formDeItem.resetValidation()
					this.campoDeNota.focus()
					throw new Error('Nota já incluida')
				}				
			});


			this.manifesto = await this.inserirItemNoManifesto.inserir({
				manifesto: this.manifesto,
				numeroDaNota: this.itemDoManifesto.numeroDaNota,
				serieDaNota: this.itemDoManifesto.serieDaNota,
			})

			this.itemDoManifesto.numeroDaNota = ''
			this.itemDoManifesto.serieDaNota = ''
			this.formDeItem.resetValidation()
			this.campoDeNota.focus()			
			
		} catch (error: any) {
			AlertModule.setError(error)
			this.campoDeNota.focus()	
		} finally {
			this.buscando = false
		}
	}	

}

function criarManifesto(): FormManifesto {
	return {
		id: null,
		motoristaId: null,
		lojaId: null,
		identificador: '',
		itens: [],
		situacao: 'Pendente',
	}
}

function criarItemDoManifesto(): NotaESerie {
	return {
		serieDaNota: null,
		numeroDaNota: null,
	}
}

export interface NotaESerie {
	serieDaNota: number | '' | null
	numeroDaNota: number | '' | null
}

