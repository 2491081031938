import { Inject } from 'inversify-props'
import type { NotaFiscalServiceAdapter } from '../fiscal/adapter'
import { FormItemDoManifesto, FormManifesto } from '@/models'
import type { ManifestoServiceAdapter } from '../manifesto'
export class InserirItemNoManifestoUseCase {
	@Inject('NotaFiscalServiceAdapter')	
	private notaFiscalService!: NotaFiscalServiceAdapter
	
	@Inject('ManifestoServiceAdapter')
	private manifestoServiceAdapter!: ManifestoServiceAdapter
	
	async inserir({
		manifesto,
		numeroDaNota,
		serieDaNota,
	}: {
		manifesto: FormManifesto
		numeroDaNota: number | '' | null
		serieDaNota: number | '' | null
	}): Promise<FormManifesto> {

		const loja = manifesto.lojaId
		
		if (!loja) {
			throw new Error('Loja não informada')
		}

		if (!numeroDaNota){
			throw new Error('Nota não informada')
		}

		if (!serieDaNota){
			throw new Error('Serie não informada')
		}

		const pagina = await this.notaFiscalService.find({
			lojaId: loja.toString(),
			nnf: numeroDaNota.toString(),
			serie: serieDaNota.toString(),
		})
		const nota = pagina.content[0]
		
		if (!nota) {
			throw new Error('Nota fiscal não encontrada')
		}

		const paginaManifesto = await this.manifestoServiceAdapter.findBySerieNota(
			loja.toString(),
			numeroDaNota.toString(),
			serieDaNota.toString(),
		)
		const manifeto = paginaManifesto.content[0];

		if (manifeto) {
			throw new Error('Nota fiscal já relacionada em outro Manifesto');
		}

		const notaId = nota.notaId;	
		const nnf = nota.nnf;	
		const serie = nota.serie;
		const id = null;

		const item: FormItemDoManifesto = {
			id,
			notaId,
			nnf,
			serie,
			sequencia: (manifesto.itens.length + 1),
		}
		return {
			...manifesto,
			itens: [
				...manifesto.itens,
				item,
			],
		}		
		
	}
}
