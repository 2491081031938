












import { Perfil, Usuario } from '@/models'
import { FindPerfilUseCase, FindUsuarioUseCase, FindManifestoUseCase } from '@/usecases'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class SeletorDeMotorista extends Vue {
	@Prop({type: Array, default: () => []}) excludes!: string[]
	@Prop({ type: Boolean }) validarManifesto?: boolean
	usuarios: Usuario[] = []	
	perfil: Perfil | null = null
	busca = ''
	findUsuarioUseCase = new FindUsuarioUseCase();
	findPerfilUseCase = new FindPerfilUseCase();		
	findManifestoUseCase = new FindManifestoUseCase();


	async created() {
		this.perfil = await this.findPerfilUseCase.findByNome('Motorista');
		if (this.perfil) {
			this.usuarios = await this.findUsuarioUseCase.findByPerfil(this.perfil.id)
			if (this.validarManifesto) {			
				this.usuarios.forEach((user, index) => {
					this.validaManifesto(user.id, index);
				});
			}
		}
	}

	async validaManifesto(usuarioId: string, indice: number) {
		const paginaManifesto = await this.findManifestoUseCase.findByMotorista(
			usuarioId,
		)
		if (paginaManifesto.content[0]) {
			this.usuarios.splice(indice,1); 
		}
	}

	get usuariosFiltradas() {		
		return this.usuarios;		
	}
}

